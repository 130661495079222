import React from 'react'
import { injectIntl, Link } from 'gatsby-plugin-intl'
// import Layout from '../components/layout'
import SEO from '../components/seo'

const Media = ({ intl }) => (
  <>
    <SEO title={intl.formatMessage({ id: 'navigation.media' })} />
    <div
      style={{
        maxWidth: `800px`,
        margin: `0 auto`,
      }}
    >
      <form>
        <div className='interfaceRow justifyCenter'>
          <Link to='#'>
            {intl.formatMessage({ id: 'pages.media.basicsetup' })}
          </Link>
        </div>
      </form>
      <div className='interfaceRow justifyCenter'>
        <Link to='#'>
          {intl.formatMessage({ id: 'pages.media.filemanager' })}
        </Link>
      </div>
    </div>
  </>
)

export default injectIntl(Media)
